import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import AddOilModal from './AddOilModal';
import {
  GET_OILS_BY_SEARCH,
  ADD_OIL_TO_INVENTORY,
} from '../util/gql';

interface AddOilContainerProps {
  onClose: () => void;
  onError: (error: string) => void;
  franchiseId: string;
  vanId: number;
  refetchItems: () => void;
}

export default function AddOilContainer({
  onClose,
  onError,
  franchiseId,
  vanId,
  refetchItems,
}: AddOilContainerProps) {
  const [availableOils, setAvailableOils] = useState([]);

  const [getOils] = useLazyQuery(GET_OILS_BY_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setAvailableOils(data.getOilsBySearch),
    onError: (err) => onError(`Failed to load parts: ${err.message}`),
  });

  const [addOilToInventory] = useMutation(ADD_OIL_TO_INVENTORY, {
    onError: (err) => onError(`Failed to add part: ${err.message}`),
  });

  const handleConfirm = async (data: { selectedOil: string }) => {
    try {
      if (data.selectedOil) {
        await addOilToInventory({
          variables: {
            franchiseId,
            vanId,
            oilId: parseInt(data.selectedOil, 10),
          },
        });
      }
      refetchItems();
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        onError(`Failed to add oil: ${error.message}`);
      } else {
        onError('Failed to add oil due to an unknown error');
      }
    }
  };

  useEffect(() => {
    getOils({
      variables: {
        searchTerm: '',
      },
    });
  }, [getOils]);

  return (
    <AddOilModal
      onClose={onClose}
      onConfirm={handleConfirm}
      availableOils={availableOils}
    />
  );
}
