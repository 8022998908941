import { useMemo, useState } from 'react';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import TextInput from './TextInput';
import CameraAccess from './CameraAccess';
import AwsUpload from './AwsUpload';
import { IncreaseInventoryModalProps } from '../interfaces/Inventory';

export default function IncreaseInventoryModal({
  partId,
  partName,
  quantity,
  setQuantity,
  pricePerItem,
  setPricePerItem,
  receiptImage,
  setReceiptImage,
  onClose,
  onSubmit,
}: IncreaseInventoryModalProps) {
  const [imageToProcess, setImageToProcess] = useState(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [
    displayPricePerItem,
    setDisplayPricePerItem,
  ] = useState<string | null>(
    pricePerItem != null ? (pricePerItem / 100).toFixed(2) : null,
  );

  const handleCaptureImage = async (capturedImage: any) => {
    setImageToProcess(capturedImage);
    setImageName(`receipt_part_${partId}_${Date.now()}.jpg`);
  };

  const onUploadComplete = (success: boolean, url: string) => {
    if (success) {
      setReceiptImage(url);
    }
    setImageToProcess(null);
    setImageName(null);
  };

  const handleQuantityChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const value = Number(target.value);
    setQuantity(value);
  };

  const handlePriceChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const { value } = target;
    setDisplayPricePerItem(value);
    const convertedPrice = value ? Math.round(parseFloat(value) * 100) : null;
    setPricePerItem(convertedPrice);
  };

  const isConfirmDisabled = useMemo(() => quantity <= 0
    || pricePerItem == null
    || pricePerItem <= 0
    || !receiptImage, [quantity, pricePerItem, receiptImage]);

  const handleConfirm = () => {
    if (pricePerItem == null) {
      return;
    }
    onSubmit({ quantity, pricePerItem, receiptImage });
  };
  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Increase Inventory Quantity</h4>

        <p className={styles.partName}>
          {partName}
        </p>

        <p className="label">Total Quantity Purchased</p>
        <TextInput
          type="number"
          min={1}
          placeholder="Enter total quantity"
          value={quantity.toString()}
          onChange={handleQuantityChange}
          isRequired
        />

        <p className="label">Price per Filter</p>
        <TextInput
          type="number"
          min={0.1}
          placeholder="Enter price per Filter"
          value={displayPricePerItem ?? ''}
          onChange={handlePriceChange}
          isRequired
        />

        {!receiptImage ? (
          <>
            <p>Add a Picture to Confirm Receipt</p>
            <CameraAccess
              onCapture={handleCaptureImage}
              className={styles.addServiceImage}
            />
            {imageToProcess && imageName && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName ?? `receipt_${Date.now()}.jpg`}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>Picture successfully uploaded</p>
        )}

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} variant="tertiary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="primary"
            inactive={isConfirmDisabled}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
