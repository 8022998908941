/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { InspectionImagesProps } from '../interfaces/Components';
import styles from '../sass/components/VehicleInspection.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import Checkbox from './Checkbox';
import Button from './Button';
import CameraAccess from './CameraAccess';
import { useAppSelector } from '../app/hooks';
import { LineItem } from '../interfaces/WorkOrder';
import AwsUpload from './AwsUpload';
import TextInput from './TextInput';

type CameraAccessState = {
  [key: number]: boolean;
};

type ServiceAccess = {
  [key: number]: boolean;
};

export default function InspectionImage({
  onClose,
  onClick,
  workOrderDetails,
  inspectionImages,
  setinspectionImages,
  brakeFluid,
  setBrakeFluid,
  windshieldFluid,
  setWindshieldFluid,
  leakInspection,
  setLeakInspection,
  oilCapLocation,
  setOilCapLocation,
  dipstickLocation,
  setDipstickLocation,
  servicesLocation,
  setServicesLocation,
  oilFilterLocation,
  setOilFilterLocation,
  isContinueButtonInactive,
}: InspectionImagesProps) {
  const [services, setServices] = useState<LineItem[]>([]);

  const cameraAccessRef = useRef<{ openCamera:() => void }>(null);
  const [oilCap, setOilCap] = useState(false);
  const [oilFiler, setOilFilter] = useState(false);
  const [oilCapFileName, setOilCapFileName] = useState('');
  const [dipstickFileName, setDipstickFileName] = useState('');
  const [dipstick, setDipstick] = useState(false);
  const [cameraAccess, setCameraAccess] = useState<CameraAccessState>({});
  const [serviceAccess, setServiceAccess] = useState<ServiceAccess>({});
  const [cameraAccessOilCap, setCameraAccessOilCap] = useState(false);
  const [cameraAccessOilFilter, setCameraAccessOilFilter] = useState(false);
  const [cameraAccessDipstick, setCameraAccessDipstick] = useState(false);

  const [imageToProcess, setImageToProcess] = useState(null);
  const [imageName, setImageName] = useState<string>('');
  const [currentId, setCurrentId] = useState<number>();

  const workOrderId = useAppSelector((state) => state.workOrder.id);

  const handleBrakeFluid = (event: ChangeEvent<HTMLInputElement>) => {
    setBrakeFluid(event.target.checked);
  };
  const handleWindshieldFluid = (event: ChangeEvent<HTMLInputElement>) => {
    setWindshieldFluid(event.target.checked);
  };
  const handleLeakInspection = (event: ChangeEvent<HTMLInputElement>) => {
    setLeakInspection(event.target.checked);
  };

  const handleOilCap = () => {
    setCameraAccessOilCap(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };

  const handleDipstick = () => {
    setCameraAccessDipstick(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };

  const handleOilFilter = () => {
    setCameraAccessOilFilter(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const toggleCameraAccess = (id: number) => {
    setCameraAccess((prev: CameraAccessState) => ({ ...prev, [id]: !prev[id] }));
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };

  useEffect(() => {
    handleOilFilter();
    handleOilCap();
    handleDipstick();
    services.forEach((service) => {
      toggleCameraAccess(service.id);
    });
    if (workOrderDetails && Array.isArray(workOrderDetails.lineitems)) {
      setServices(workOrderDetails.lineitems);
    }
  }, [workOrderDetails, services]);
  const handleCaptureOilCap = async (capturedImage: any) => {
    const fileName = `${Date.now()}-OilCap.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setOilCap(true);
  };
  const handleCaptureOilFilter = async (capturedImage: any) => {
    const fileName = `${Date.now()}-OilFilter.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setOilFilter(true);
  };
  const handleCaptureDipstick = async (capturedImage: any) => {
    const fileName = `${Date.now()}-Dipstick.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setDipstick(true);
  };
  const handleCapture = (id: number, name: string) => async (image: any) => {
    const fileName = `${Date.now()}-ID-${workOrderId}-${name}.png`;
    setImageName(fileName);
    setImageToProcess(image);
    setServiceAccess((prev: ServiceAccess) => ({ ...prev, [id]: !prev[id] }));
    setCurrentId(id);
  };

  const onUploadComplete = (success: any, url: string) => {
    if (success) {
      if (url.includes('OilCap')) {
        setOilCapLocation(url);
        setOilCap(false);
        setOilCapFileName(url);
      } else if (url.includes('OilFilter')) {
        setOilFilterLocation(url);
      } else if (url.includes('Dipstick')) {
        setDipstickLocation(url);
        setDipstick(false);
        setDipstickFileName(url);
      } else {
        console.log('Image URL saving failed');
      }
    } else {
      console.error('');
    }
    setImageToProcess(null);
    setImageName('');
  };
  const onUploadCompleteService = (success: boolean, url: string) => {
    if (success && setServicesLocation && currentId) {
      setServicesLocation((prevServices: any) => [
        ...prevServices,
        { id: currentId, location: url },
      ]);
      setServiceAccess((prev: ServiceAccess) => ({ ...prev, [currentId]: !prev[currentId] }));
    } else {
      console.error('');
    }
    setImageToProcess(null);
    setImageName('');
  };
  return (
    <div className={styles.body}>
      <h4>Step 3/6: Add Services Performed</h4>
      <p>
        This step needs to be completed after the services were performed.
        Please add information and a proof of fix on the services that were performed.
      </p>
      {
        oilCapLocation === '' ? (
          <>
            <p>
              Add a Picture of the Oil Cap after completed service:
            </p>
            <br />
            {cameraAccessOilCap
            && <CameraAccess onCapture={handleCaptureOilCap} className={styles.addServiceImage} />}
            {imageToProcess && imageName && oilCap && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>
            Picture successfully uploaded
          </p>
        )
      }
      {
        oilFilterLocation === '' ? (
          <>
            <p>
              Add a Picture of the Oil Filter:
            </p>
            <br />
            {cameraAccessOilFilter
            && <CameraAccess onCapture={handleCaptureOilFilter} className={styles.addServiceImage} />}
            {imageToProcess && imageName && oilFiler && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>
            Picture successfully uploaded
          </p>
        )
      }
      {
        dipstickLocation === '' ? (
          <>
            <p>
              Add a Picture of the dipstick:
            </p>
            <br />
            {cameraAccessDipstick
            && <CameraAccess onCapture={handleCaptureDipstick} className={styles.addServiceImage} />}
            {imageToProcess && imageName && dipstick && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>
            Picture successfully uploaded
          </p>
        )
      }
      <br />
      <p>
        Check Boxes if services were done
      </p>
      <div className={styles.individualTireWrapper}>
        <div className={styles.individualBoxInput}>
          <div className={styles.textContainer}>
            <p className={styles.textStyle}>Brake Fluid Refilled?</p>
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox
              isChecked={brakeFluid}
              onChange={handleBrakeFluid}
            />
          </div>
        </div>
        <div className={styles.individualBoxInput}>
          <div className={styles.textContainer}>
            <p className={styles.textStyle}>Windshield Wiper Fluid filled?</p>
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox
              isChecked={windshieldFluid}
              onChange={handleWindshieldFluid}
            />
          </div>
        </div>
        <div className={styles.individualBoxInput}>
          <div className={styles.textContainer}>
            <p className={styles.textStyle}>Vehicle Inspected for Leaks?</p>
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox
              isChecked={leakInspection}
              onChange={handleLeakInspection}
            />
          </div>
        </div>
      </div>
      <br />
      <div>
        {services.map((service) => (
          <div key={service.id}>
            <p className={styles.captionText}>
              Add a Picture
              {' '}
              {service.name}
              {' '}
              after completed service:
            </p>
            {
              servicesLocation?.find((sl) => sl.id === service.id)
                ? (
                  <>
                    <p className={styles.captionText}>Picture Successfully Uploaded</p>
                  </>
                ) : (
                  <>
                    <br />
                    {/* <Button
                      onClick={() => toggleCameraAccess(service.id)}
                      variant="primary"
                      className={styles.addServiceImage}
                    >
                      Add Picture
                    </Button> */}
                    {cameraAccess[service.id] && (
                    <CameraAccess onCapture={handleCapture(service.id, service.name)} className={styles.addServiceImage} />
                    )}
                    {imageToProcess && imageName && serviceAccess[service.id] && (
                      <AwsUpload
                        capturedImage={imageToProcess}
                        filename={imageName}
                        onUploadComplete={onUploadCompleteService}
                      />
                    )}
                  </>
                )
            }
          </div>
        ))}
      </div>
      <br />
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onClick}
        inactive={isContinueButtonInactive}
        label="Continue"
      />
    </div>
  );
}
