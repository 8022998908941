/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import Tesseract from 'tesseract.js';
import { AdditionalVehicleInfoProps } from '../interfaces/Components';
import styles from '../sass/components/AdditionalVehicleInfo.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import TextInput from './TextInput';
import CameraAccess from './CameraAccess';
import Button from './Button';
import { useAppSelector } from '../app/hooks';
import AwsUpload from './AwsUpload';

export default function AdditionalVehicleInfo(
  {
    vehicleVin,
    vin,
    setVin,
    mileage,
    setMileage,
    mileageLocation,
    setMileageLocation,
    vinLocation,
    setVinLocation,
    vehicleLocationFrontLeft,
    setVehicleLocationFrontLeft,
    vehicleLocationFrontRight,
    setVehicleLocationFrontRight,
    vehicleLocationRearLeft,
    setVehicleLocationRearLeft,
    vehicleLocationRearRight,
    setVehicleLocationRearRight,
    onClose,
    onClick,
    isContinueButtonInactive,
  }: AdditionalVehicleInfoProps,
) {
  const cameraAccessRef = useRef<{ openCamera:() => void }>(null);
  const [cameraAccessVin, setCameraAccessVin] = useState(false);
  const [cameraAccessMileage, setCameraAccessMileage] = useState(false);
  const [isVinUploaded, setIsVinUploaded] = useState(false);
  const [isMileageUploaded, setIsMileageUploaded] = useState(false);
  const [isVehicleUploadedFrontLeft, setIsVehicleUploadedFrontLeft] = useState(false);
  const [isVehicleUploadedFrontRight, setIsVehicleUploadedFrontRight] = useState(false);
  const [isVehicleUploadedRearLeft, setIsVehicleUploadedRearLeft] = useState(false);
  const [isVehicleUploadedRearRight, setIsVehicleUploadedRearRight] = useState(false);
  const [cameraAccessVehicleFrontLeft, setCameraAccessVehicleFrontLeft] = useState(false);
  const [cameraAccessVehicleFrontRight, setCameraAccessVehicleFrontRight] = useState(false);
  const [cameraAccessVehicleRearLeft, setCameraAccessVehicleRearLeft] = useState(false);
  const [cameraAccessVehicleRearRight, setCameraAccessVehicleRearRight] = useState(false);

  const [imageToProcess, setImageToProcess] = useState(null);
  const [imageName, setImageName] = useState<string>('');

  const workOrderId = useAppSelector((state) => state.workOrder.id);

  const recognizedVin = (capturedImage: any) => {
    Tesseract.recognize(
      capturedImage,
      'eng',
      {
      },
    ).then(({ data: { text } }) => {
      const vinRegex = /[A-HJ-NPR-Z0-9]{17}/g;
      const vins = text.match(vinRegex);
      if (vins) {
        setVin(vins.toString());
      }
    });
  };
  const recognizedMileage = (capturedImage: any) => {
    Tesseract.recognize(
      capturedImage,
      'eng',
      {
      },
    ).then(({ data: { text } }) => {
      const mileageRegex = /\b\d{4,7}k?m?\b/g;
      const mileages = text.match(mileageRegex);
      if (mileages) {
        // eslint-disable-next-line max-len
        const numericMileages = mileages.map((mile) => Number(mile.replace('m', '')));
        const firstMileage = numericMileages[0];
        setMileage(firstMileage);
      }
    });
  };
  const handleVin = () => {
    setCameraAccessVin(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleVehicleFrontLeft = () => {
    setCameraAccessVehicleFrontLeft(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleVehicleFrontRight = () => {
    setCameraAccessVehicleFrontRight(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleVehicleRearLeft = () => {
    setCameraAccessVehicleRearLeft(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleVehicleRearRight = () => {
    setCameraAccessVehicleRearRight(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  const handleMileage = () => {
    setCameraAccessMileage(true);
    if (cameraAccessRef.current) {
      cameraAccessRef.current.openCamera();
    }
  };
  useEffect(() => {
    handleMileage();
    handleVin();
    handleVehicleFrontLeft();
    handleVehicleFrontRight();
    handleVehicleRearLeft();
    handleVehicleRearRight();
  }, []);

  const handleCaptureVin = async (capturedImage: any) => {
    recognizedVin(capturedImage);
    const fileName = `${Date.now()}-VehicleVIN.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setIsVinUploaded(true);
  };
  const handleCaptureMileage = async (capturedImage: any) => {
    recognizedMileage(capturedImage);
    const fileName = `${Date.now()}-VehicleMileage.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setIsMileageUploaded(true);
  };
  const handleCaptureVehicleFrontLeft = async (capturedImage: any) => {
    const fileName = `${Date.now()}-VehicleFrontLeft.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setIsVehicleUploadedFrontLeft(true);
  };
  const handleCaptureVehicleFrontRight = async (capturedImage: any) => {
    const fileName = `${Date.now()}-VehicleFrontRight.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setIsVehicleUploadedFrontRight(true);
  };
  const handleCaptureVehicleRearLeft = async (capturedImage: any) => {
    const fileName = `${Date.now()}-VehicleRearLeft.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setIsVehicleUploadedRearLeft(true);
  };
  const handleCaptureVehicleRearRight = async (capturedImage: any) => {
    const fileName = `${Date.now()}-VehicleRearRight.png`;
    setImageName(fileName);
    setImageToProcess(capturedImage);
    setIsVehicleUploadedRearRight(true);
  };

  const onUploadComplete = (success: any, url: string) => {
    if (success) {
      if (url.includes('FrontLeft')) {
        setVehicleLocationFrontLeft(url);
        setIsVehicleUploadedFrontLeft(false);
      } else if (url.includes('FrontRight')) {
        setVehicleLocationFrontRight(url);
        setIsVehicleUploadedFrontRight(false);
      } else if (url.includes('RearLeft')) {
        setVehicleLocationRearLeft(url);
        setIsVehicleUploadedRearLeft(false);
      } else if (url.includes('RearRight')) {
        setVehicleLocationRearRight(url);
        setIsVehicleUploadedRearRight(false);
      } else if (url.includes('VehicleVIN')) {
        setVinLocation(url);
        setIsVinUploaded(false);
      } else if (url.includes('VehicleMileage')) {
        setMileageLocation(url);
        setIsMileageUploaded(false);
      } else {
        console.log('saving URL Failed');
      }
    } else {
      console.error('');
    }
    // Reset states to allow for another upload
    setImageToProcess(null);
    setImageName('');
  };
  return (
    <div className={styles.body}>
      <h4>Step 4/6: Enter vehicle VIN and mileage</h4>
      <p>
        Kindly snap a photo of the VIN and odometer. The system will
        automatically populate the relevant text fields for you.
        Please take a moment to verify that the entered data is accurate.
      </p>
      <p className={styles.title}>
        Please take a picture of the vehicle from all four corners
      </p>
      <br />
      <div className={styles.individualTireWrapper}>
        {
        vehicleLocationFrontLeft === '' ? (
          <>
            {cameraAccessVehicleFrontLeft
            && (
            <CameraAccess
              onCapture={handleCaptureVehicleFrontLeft}
              className={styles.addVehicleButton}
              buttonLabel="Front Left"
            />
            )}
            {imageToProcess && imageName && isVehicleUploadedFrontLeft && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>
            Front Left successfully uploaded
          </p>
        )
      }
        {
        vehicleLocationFrontRight === '' ? (
          <>
            {cameraAccessVehicleFrontLeft
            && (
            <CameraAccess
              onCapture={handleCaptureVehicleFrontRight}
              className={styles.addVehicleButton}
              buttonLabel="Front Right"
            />
            )}
            {imageToProcess && imageName && isVehicleUploadedFrontRight && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>
            Front Right successfully uploaded
          </p>
        )
      }
      </div>
      <br />
      <div className={styles.individualTireWrapper}>
        {
        vehicleLocationRearLeft === '' ? (
          <>
            {cameraAccessVehicleFrontLeft
            && (
            <CameraAccess
              onCapture={handleCaptureVehicleRearLeft}
              className={styles.addVehicleButton}
              buttonLabel="Rear Left"
            />
            )}
            {imageToProcess && imageName && isVehicleUploadedRearLeft && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>
            Rear Left successfully uploaded
          </p>
        )
      }
        {
        vehicleLocationRearRight === '' ? (
          <>
            {cameraAccessVehicleFrontLeft
            && (
            <CameraAccess
              onCapture={handleCaptureVehicleRearRight}
              className={styles.addVehicleButton}
              buttonLabel="Rear Right"
            />
            )}
            {imageToProcess && imageName && isVehicleUploadedRearRight && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>
            Rear Right successfully uploaded
          </p>
        )
      }
      </div>
      <br />
      <div className={styles.individualTireWrapper}>
        <div className={styles.additionalInfo}>
          <TextInput
            className={styles.input_box}
            title="Vehicle VIN"
            placeholder={vehicleVin}
                // disabled={vehicleVin !== null}
            onChange={(e) => setVin(e.currentTarget.value)}
            value={vin || ''}
            isRequired
          />
          {
                vinLocation === '' ? (
                  <>
                    {cameraAccessVin
                    && <CameraAccess onCapture={handleCaptureVin} className={styles.editButtonInline2} />}
                    {imageToProcess && imageName && isVinUploaded && (
                    <AwsUpload
                      capturedImage={imageToProcess}
                      filename={imageName}
                      onUploadComplete={onUploadComplete}
                    />
                    )}
                  </>
                ) : (
                  <p>
                    Picture successfully uploaded
                  </p>
                )
              }
        </div>
        <div className={styles.additionalInfo}>
          <TextInput
            className={styles.input_box}
            title="Vehicle Mileage"
            placeholder="Enter vehicle mileage"
            onChange={(e) => setMileage(parseInt(e.currentTarget.value, 10))}
            type="number"
            isRequired
            value={mileage ? mileage.toString() : ''}
            min={1}
          />
          {
            mileageLocation === '' ? (
              <>
                {cameraAccessMileage
                && <CameraAccess onCapture={handleCaptureMileage} className={styles.editButtonInline2} />}
                {imageToProcess && imageName && isMileageUploaded && (
                <AwsUpload
                  capturedImage={imageToProcess}
                  filename={imageName}
                  onUploadComplete={onUploadComplete}
                />
                )}
              </>
            ) : (
              <p>
                Picture successfully uploaded
              </p>
            )
          }
        </div>
      </div>
      <br />
      <ModalButtonsContainer
        onClose={onClose}
        onClick={onClick}
        inactive={isContinueButtonInactive}
        label="Continue"
      />
    </div>
  );
}
