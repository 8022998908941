import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  FIND_AVAILABLE_PART_ITEMS,
  FIND_AVAILABLE_OILS,
} from '../util/gql';
import { InventoryInspectionProps } from '../interfaces/Components';
import styles from '../sass/components/InventoryInspection.module.scss';
import ModalButtonsContainer from './ModalButtonsContainer';
import SearchableSelect from './SearchableSelect';
import { calculateOilByCountry } from '../util/oilHelper';
import { useAppSelector } from '../app/hooks';

interface InventoryItem {
  inventoryPartItemId: string;
  partItemId: number;
  partItemName: string;
  quantityAvailable: number;
  isPreferred: boolean;
}

interface FindAvailablePartItemsData {
  findAvailablePartItems: PartTypeNew[];
}

interface PartTypeNew {
  partTypeId: number;
  availablePartItems: InventoryItem[];
}

interface InventoryOilItem {
  id: number;
  oilId: number;
  quantity: number;
  oil: {
    id: number;
    type: string;
    weight: string;
  };
}

interface InventoryOilData {
  findAvailableOils: InventoryOilItem[];
}

export default function InventoryInspection({
  onClose,
  onClick,
  requiredParts,
  requiredOilId,
  requiredOilVolume,
  workOrderDetails,
  isContinueButtonInactive,
  inventoryOilFilterId,
  setInventoryOilFilterId,
  inventoryCabinFilterId,
  setInventoryCabinFilterId,
  inventoryEngineFilterId,
  setInventoryEngineFilterId,
  setOilId,
}: InventoryInspectionProps) {
  const { data, loading, error } = useQuery<FindAvailablePartItemsData>(
    FIND_AVAILABLE_PART_ITEMS, {
      variables: {
        requiredPartsIds: requiredParts.map((part) => part.id),
        franchiseId: workOrderDetails?.franchiseId,
        vanId: workOrderDetails?.vanId,
        vehicleId: workOrderDetails?.vehicleId,
      },
      fetchPolicy: 'network-only',
    },
  );
  const {
    data: inventoryOilData,
    loading: oilLoading,
    error: oilError,
  } = useQuery<InventoryOilData>(
    FIND_AVAILABLE_OILS,
    {
      variables: {
        franchiseId: workOrderDetails?.franchiseId,
        vanId: workOrderDetails?.vanId,
      },
      fetchPolicy: 'network-only',
    },
  );
  const isOilRequired = requiredOilId !== undefined
  && requiredOilVolume !== undefined;
  const isInventoryRequired = requiredParts.length > 0 || isOilRequired;
  const { countryCode } = useAppSelector((state) => state.franchise);

  const getSelectedValue = (
    inventoryPartItemId: string,
    availablePartItems: any[],
  ) => {
    const selectedInventory = availablePartItems.find(
      (inventory) => inventory.inventoryPartItemId === inventoryPartItemId,
    );
    if (selectedInventory) {
      return {
        value: Number(selectedInventory.inventoryPartItemId),
        label: `${selectedInventory.partItemName}
        (Qty: ${selectedInventory.quantityAvailable}) - Recommended`,
      };
    }

    return undefined;
  };
  const inventoryOilItems = inventoryOilData?.findAvailableOils || [];

  const [selectedOilId, setSelectedOilId] = useState<number | null>(() => {
    if (requiredOilId) {
      const oilId = requiredOilId;
      const selectedOilItem = inventoryOilItems.find(
        (item) => item.oil.id === oilId,
      );

      if (
        selectedOilItem
        && (!requiredOilVolume
          || selectedOilItem.quantity >= requiredOilVolume)
      ) {
        return oilId;
      }
    }
    return null;
  });

  const handleFilterSelection = (filterType: string, inventoryId: string) => {
    switch (filterType) {
      case 'Oil Filter':
        setInventoryOilFilterId(inventoryId);
        break;
      case 'Cabin Air Filter':
        setInventoryCabinFilterId(inventoryId);
        break;
      case 'Air Filter':
        setInventoryEngineFilterId(inventoryId);
        break;
      default:
        break;
    }
  };

  const handleOilSelect = (oilId: number) => {
    setSelectedOilId(oilId);
    setOilId(oilId);
  };
  useEffect(() => {
    if (selectedOilId) {
      setOilId(selectedOilId);
    }
  }, [selectedOilId, setOilId]);

  const handleOnClose = (): void => {
    setInventoryOilFilterId('');
    setInventoryCabinFilterId('');
    setInventoryEngineFilterId('');
    setSelectedOilId(null);
    setOilId(undefined);
    onClose();
  };

  if (loading || oilLoading) return <p>Loading inventory...</p>;
  if (error || oilError) {
    return (
      <p>
        Error loading inventory:
        {error?.message || oilError?.message}
      </p>
    );
  }
  return (
    <div className={styles.body}>
      <h4>Step 0/6: Enter Parts and Oil Details</h4>

      {!isInventoryRequired ? (
        <p>
          Inventory selection is not required for this service.
          You can proceed to the next step.
        </p>
      ) : (
        <>
          <p>
            Before completing this order, please provide the
            vehicle&apos;s inspection data.
            Select the parts and oils required for the service.
            Once all the information is provided,
            proceed to enter the service details.
          </p>

          {data?.findAvailablePartItems.map(
            ({ partTypeId, availablePartItems }) => {
              const requiredPart = requiredParts.find(
                (part) => part.id === partTypeId,
              );
              let selectedValue;

              if (!requiredPart) {
                selectedValue = undefined;
              } else {
                const partName = requiredPart.name;
                let inventoryPartItemId;
                const preferredInventoryPartItem = availablePartItems.find(
                  (item) => item.isPreferred && item.quantityAvailable > 0,
                );
                switch (partName) {
                  case 'Oil Filter':
                    inventoryPartItemId = inventoryOilFilterId
                || preferredInventoryPartItem?.inventoryPartItemId;
                    if (inventoryPartItemId) {
                      setInventoryOilFilterId(inventoryPartItemId);
                    }
                    break;
                  case 'Cabin Air Filter':
                    inventoryPartItemId = inventoryCabinFilterId
                || preferredInventoryPartItem?.inventoryPartItemId;
                    if (inventoryPartItemId) {
                      setInventoryCabinFilterId(inventoryPartItemId);
                    }
                    break;
                  case 'Air Filter':
                    inventoryPartItemId = inventoryEngineFilterId
                || preferredInventoryPartItem?.inventoryPartItemId;
                    if (inventoryPartItemId) {
                      setInventoryEngineFilterId(inventoryPartItemId);
                    }
                    break;
                  default:
                    selectedValue = undefined;
                    break;
                }

                if (inventoryPartItemId) {
                  selectedValue = getSelectedValue(
                    inventoryPartItemId,
                    availablePartItems,
                  );
                }
              }

              return (
                <div key={partTypeId} className={styles.section}>
                  <h4>{requiredPart?.name || `Part Type ${partTypeId}`}</h4>
                  {availablePartItems.length === 0 ? (
                    <p>
                      {`No ${requiredPart?.name}s available in the inventory, 
                please restock before completing the inspection.`}
                    </p>
                  ) : (
                    <SearchableSelect
                      title={`Select ${requiredPart?.name || 'Part'}`}
                      value={selectedValue}
                      placeholder={`Search or Select ${requiredPart?.name
                    || 'Part'}`}
                      customers={availablePartItems.map((inventory) => ({
                        value: Number(inventory.inventoryPartItemId),
                        label: `${inventory.partItemName} 
                      (Qty: ${inventory.quantityAvailable}) ${
                          inventory.isPreferred ? '- Recommended' : ''
                        }`,
                        isDisabled: inventory.quantityAvailable === 0,
                      }))}
                      onSelect={(selected) => {
                        handleFilterSelection(
                          requiredPart?.name || 'Filter',
                          String(selected),
                        );
                      }}
                    />
                  )}
                </div>
              );
            },
          )}

          {isOilRequired && (
          <div className={styles.section}>
            <h4>Select Oil</h4>
            {inventoryOilItems.length > 0 ? (
              (() => {
                const selectedOil = inventoryOilItems
                  .find((item) => item.oil.id === selectedOilId)?.oil;

                const selectedOilItem = inventoryOilItems.find(
                  (item) => item.oil.id === selectedOilId,
                );

                const adjustedSelectedOilQuantity = selectedOilItem
                  ? calculateOilByCountry(String(countryCode),
                    selectedOilItem.quantity)
                  : 0;

                const adjustedOilItems = inventoryOilItems.map((item) => {
                  const adjustedQuantity = calculateOilByCountry(
                    String(countryCode),
                    item.quantity,
                  );

                  return {
                    value: item.oil.id,
                    label: `${item.oil.type} ${item.oil.weight} 
                  (Qty: ${adjustedQuantity}
                  ${countryCode === 'CA' ? 'L' : 'QT'}) ${
                      item.oil.id === requiredOilId ? '- Recommended' : ''
                    }`,
                    isDisabled: requiredOilVolume
                      ? item.quantity < requiredOilVolume
                      : true,
                  };
                });

                return (
                  <SearchableSelect
                    title="Available Oils"
                    value={
            selectedOil
            && (selectedOilItem && requiredOilVolume
              && selectedOilItem?.quantity >= requiredOilVolume)
              ? {
                label: `${selectedOil.type} ${selectedOil.weight} 
                (Qty: ${adjustedSelectedOilQuantity}
                ${countryCode === 'CA' ? 'L' : 'QT'})
                ${selectedOil.id === requiredOilId
                  ? '- Recommended' : ''}`,
                value: selectedOilId || 0,
              }
              : undefined
          }
                    placeholder="Search or Select Oil"
                    customers={adjustedOilItems}
                    onSelect={handleOilSelect}
                  />
                );
              })()
            ) : (
              <p>
                No oil is available in the inventory,
                please restock before completing the inspection.
              </p>
            )}
          </div>
          )}
        </>
      )}

      <ModalButtonsContainer
        onClose={handleOnClose}
        onClick={onClick}
        inactive={isContinueButtonInactive}
        label="Continue"
      />
    </div>
  );
}
