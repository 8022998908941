import { useState } from 'react';
import styles from '../sass/components/AddPartModal.module.scss';
import Button from './Button';
import SearchableSelect from './SearchableSelect';
import ModalCloseButton from './ModalCloseButton';

interface Oil {
  id: string;
  type: string;
  weight: string;
}

interface AddOilModalProps {
  onClose: () => void;
  onConfirm: (data: { selectedOil: string }) => void;
  availableOils: Oil[];
}

export default function AddOilModal({
  onClose,
  onConfirm,
  availableOils = [],
}: AddOilModalProps) {
  const [selectedOil, setSelectedOil] = useState<number | null>(null);

  const handleOilSelect = (selectedOilId: number) => {
    setSelectedOil(selectedOilId);
  };

  const handleConfirm = () => {
    if (selectedOil) {
      onConfirm({ selectedOil: selectedOil.toString() });
    }
  };

  const isConfirmDisabled = !selectedOil;

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Select Existing Oil</h4>
        <p>
          Choose from existing oils.
        </p>
        <div className={styles.input}>
          <SearchableSelect
            title="Available Oils"
            value={
      selectedOil
        ? (() => {
          const selectedOilDetails = availableOils.find(
            (oil) => Number(oil.id) === selectedOil,
          );
          return {
            label: `${selectedOilDetails?.type || ''} 
            ${selectedOilDetails?.weight || ''}`,
            value: selectedOil,
          };
        })()
        : undefined
    }
            placeholder="Search or Select Oil"
            customers={
      availableOils.map((oil) => ({
        value: Number(oil.id),
        label: `${oil.type} ${oil.weight}`,
      }))
    }
            onSelect={handleOilSelect}
          />
        </div>

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} variant="tertiary">Cancel</Button>
          <Button
            onClick={handleConfirm}
            variant="primary"
            inactive={isConfirmDisabled}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
