import { useState } from 'react';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import IncreaseInventoryModal from './IncreaseInventoryModal';

interface IncreaseInventoryContainerProps {
  partId: string;
  partName: string;
  onClose: () => void;
  onSubmit: (data: {
    quantity: number;
    pricePerItem: number | null;
    receiptImage: string }) => void;
}

export default function IncreaseInventoryContainer({
  partId,
  partName,
  onClose,
  onSubmit,
}: IncreaseInventoryContainerProps) {
  const [quantity, setQuantity] = useState<number>(1);
  const [pricePerItem, setPricePerItem] = useState<number | null>(null);
  const [receiptImage, setReceiptImage] = useState<string>('');

  const handleSubmit = () => {
    onSubmit({
      quantity,
      pricePerItem,
      receiptImage,
    });
    onClose();
  };

  return (
    <div className={styles.container}>
      <IncreaseInventoryModal
        partName={partName}
        partId={partId}
        quantity={quantity}
        setQuantity={setQuantity}
        pricePerItem={pricePerItem}
        setPricePerItem={setPricePerItem}
        receiptImage={receiptImage}
        setReceiptImage={setReceiptImage}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
