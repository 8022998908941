import Select from 'react-select';
import styles from '../sass/components/SearchableSelect.module.scss';

interface SearchableSelectProps {
    title?: string,
    placeholder?: string,
    value?: { value: number; label: string | undefined; },
    onSelect: (id: number) => void
    customers: { value: number; label: string; isDisabled?: boolean}[],
  }
export default function SearchableSelect({
  title,
  placeholder,
  onSelect,
  customers,
  value,
}: SearchableSelectProps) {
  const customStyles = {
    input: (defaultStyles: any) => ({
      ...defaultStyles,
      marginLeft: '0.75rem',
    }),
    indicatorSeparator: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '0px',
    }),
    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: '#000',
      backgroundColor: state.isSelected ? '#F0F5F8' : '#fff',
      opacity: state.isDisabled ? 0.5 : 1,
      cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#828282',
      marginLeft: '0.75rem',
    }),
    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: '#bdbdbd',
      backgroundColor: '#F2F2F2',
      boxShadow: 'none',
      height: '48px',
      borderRadius: '0px',
      borderColor: state.isFocused ? 'black' : '#F2F2F2',
      ':hover': {
        borderColor: 'black',
      },
    }),
    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '150px',
      overflowY: 'auto',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '150px',
      padding: '0',
      overflowY: 'auto',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#000',
      marginLeft: '0.75rem',
    }),
  };
  return (
    <div className={styles.box}>
      <p className="label">
        {title}
      </p>
      <Select
        styles={customStyles}
        value={value}
        options={customers}
        placeholder={placeholder}
        onChange={(e) => onSelect(e ? e.value : 0)}
        isSearchable
      />
    </div>
  );
}
