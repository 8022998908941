import { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import styles from '../sass/components/AddPartModal.module.scss';
import Button from './Button';
import Selector from './Selector';
import SearchableSelect from './SearchableSelect';
import ModalCloseButton from './ModalCloseButton';
import {
  GET_YEARS,
  GET_MAKES_BY_YEAR,
  GET_MODELS_BY_YEAR_AND_MAKE,
  GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR,
} from '../util/gql';
import Field from './Field';
import TextInput from './TextInput';

interface Part {
  id: string;
  name: string;
  typeId: string;
}

interface AddPartModalProps {
  onClose: () => void;
  onConfirm: (data: {
    selectedPart: string;
    newPartName: string;
    selectedType: string;
    vehicleId: number;
  }) => void;
  partTypes: { id: string; name: string }[];
  availableParts: Part[];
  setSelectedType: (typeId: string) => void;
  loading?: boolean;
}

export default function AddPartModal({
  onClose,
  onConfirm,
  partTypes = [],
  availableParts = [],
  setSelectedType,
  loading = false,
}: AddPartModalProps) {
  const [selectedPart, setSelectedPart] = useState<number | null>(null);
  const [newPartName, setNewPartName] = useState('');
  const [isAddingNewPart, setIsAddingNewPart] = useState(false);
  const [selectedType, setLocalSelectedType] = useState('');
  const [linkedVehicle, setLinkedVehicle] = useState({
    year: '',
    make: '',
    model: '',
    engineId: null,
  });

  const { data: yearData } = useQuery(GET_YEARS);

  const [loadMakeData, { data: makeData }] = useLazyQuery(GET_MAKES_BY_YEAR);
  const [
    loadModelData, { data: modelData },
  ] = useLazyQuery(GET_MODELS_BY_YEAR_AND_MAKE);
  const [
    loadVehicleData, { data: vehicleData },
  ] = useLazyQuery(GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR);

  useEffect(() => {
    if (linkedVehicle.year) {
      loadMakeData({ variables: { year: linkedVehicle.year } });
    }
  }, [linkedVehicle.year, loadMakeData]);

  useEffect(() => {
    if (linkedVehicle.year && linkedVehicle.make) {
      loadModelData({
        variables: { year: linkedVehicle.year, make: linkedVehicle.make },
      });
    }
  }, [linkedVehicle.year, linkedVehicle.make, loadModelData]);

  useEffect(() => {
    if (
      linkedVehicle.year
      && linkedVehicle.make
      && linkedVehicle.model
    ) {
      loadVehicleData({
        variables: {
          year: parseInt(linkedVehicle.year, 10),
          make: linkedVehicle.make,
          model: linkedVehicle.model,
        },
      });
    }
  }, [linkedVehicle.year,
    linkedVehicle.make,
    linkedVehicle.model,
    loadVehicleData]);

  const handleTypeSelect = (typeId: string) => {
    setLocalSelectedType(typeId);
    setSelectedType(typeId);
    setSelectedPart(null);
  };

  const handlePartSelect = (selectedPartId: number) => {
    setSelectedPart(selectedPartId);
  };

  const handleVehicleSelection = (field: string, value: string | number) => {
    setLinkedVehicle((prev) => ({
      ...prev,
      [field]: value,
      ...(field !== 'engineId' ? { engineId: null } : {}),
    }));
  };

  const handleConfirm = () => {
    const data = {
      selectedPart: isAddingNewPart ? '' : selectedPart?.toString() || '',
      newPartName,
      selectedType,
      vehicleId: vehicleData?.getVehiclesByMakeModelAndYear[0]?.id,
    };
    onConfirm(data);
  };

  const isConfirmDisabled = !selectedType
    || (!isAddingNewPart && !selectedPart)
    || (isAddingNewPart && (!newPartName || !linkedVehicle.engineId));
  const isVehicleDisabled = !selectedType
  || (isAddingNewPart && !newPartName);

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>{isAddingNewPart ? 'Add New Part' : 'Select Existing Part'}</h4>
        <p>
          {isAddingNewPart ? (
            <>
              Create a new part and link it to a vehicle by selecting
              the year, make, model, and engine of the vehicle.
              {' '}
              <br />
              Additional vehicles can be added later when performing
              the actual service. You can also adjust or change the vehicle
              associated with the filter in the first step of the service
              process for complete flexibility.
            </>
          ) : (
            'Choose from existing parts or add a new part if not listed.'
          )}
        </p>

        {!isAddingNewPart ? (
          <div className={styles.section}>
            <Field
              id="part-type-select"
              label="Part Type"
            >
              <Selector
                id="part-type-select"
                label="Part Type"
                value={selectedType}
                onChange={(e) => handleTypeSelect(e.target.value)}
                options={partTypes.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
              />
            </Field>
            <div className={styles.input}>
              <SearchableSelect
                title="Available Parts"
                value={
                  selectedPart
                    ? {
                      label: availableParts.find(
                        (part) => Number(part.id) === selectedPart,
                      )?.name || '',
                      value: selectedPart,
                    }
                    : undefined
              }
                placeholder="Search or Select Oil"
                customers={
                  availableParts.map((part) => ({
                    value: Number(part.id),
                    label: part.name,
                  }))
                }
                onSelect={handlePartSelect}
              />
            </div>
            <Button
              onClick={() => setIsAddingNewPart(true)}
              variant="secondary"
            >
              + Add New Part
            </Button>
          </div>
        ) : (
          <div className={styles.section}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <Field
                  id="part-type-select"
                  label="Part Type"
                >
                  <Selector
                    id="new-part-type-select"
                    label="New Part Type"
                    value={selectedType}
                    onChange={(e) => handleTypeSelect(e.target.value)}
                    options={partTypes.map((type) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                  />
                </Field>
                <TextInput
                  className={styles.input_box}
                  title="Part name"
                  placeholder="Enter part namee"
                  onChange={(e) => setNewPartName(e.currentTarget.value)}
                  type="text"
                  isRequired
                  value={newPartName}
                  min={1}
                />
              </div>
              <div className={styles.column}>
                <Field
                  id="field-year"
                  label="Year"
                >
                  <Selector
                    id="selector-year"
                    label="Year"
                    value={linkedVehicle.year}
                    disabled={isVehicleDisabled}
                    onChange={
                    (e) => handleVehicleSelection('year', e.target.value)
                  }
                    options={
                  yearData?.getAllVehicleYears?.map((y: { year: string }) => ({
                    value: y.year,
                    label: y.year,
                  })) || []
                }
                  />
                </Field>
                <Field
                  id="make"
                  label="Make"
                >
                  <Selector
                    id="make"
                    label="Make"
                    value={linkedVehicle.make}
                    disabled={!linkedVehicle.year}
                    onChange={
                    (e) => handleVehicleSelection('make', e.target.value)
                  }
                    options={
                  makeData?.getMakesByYear?.map((m: { make: string }) => ({
                    value: m.make,
                    label: m.make,
                  })) || []
                }
                  />
                </Field>
                <Field
                  id="model"
                  label="Model"
                >
                  <Selector
                    id="model"
                    label="Model"
                    value={linkedVehicle.model}
                    disabled={!linkedVehicle.make}
                    onChange={
                    (e) => handleVehicleSelection('model', e.target.value)
                  }
                    options={
                  modelData?.getModelsByYearAndMake?.map(
                    (m: { model: string }) => ({
                      value: m.model,
                      label: m.model,
                    }),
                  ) || []
                }
                  />
                </Field>
                <Field
                  id="engine"
                  label="Engine"
                >
                  <Selector
                    id="engine"
                    label="Engine"
                    value={linkedVehicle.engineId || ''}
                    disabled={!linkedVehicle.model}
                    onChange={
                    (e) => handleVehicleSelection('engineId',
                      Number(e.target.value))
                  }
                    options={
                  vehicleData?.getVehiclesByMakeModelAndYear?.map(
                    (v: { id: number; engine: string }) => ({
                      value: v.id,
                      label: v.engine,
                    }),
                  ) || []
                }
                  />
                </Field>
              </div>
            </div>
            <Button
              onClick={() => setIsAddingNewPart(false)}
              variant="secondary"
              className={styles.toggleButton}
            >
              Back to List
            </Button>
          </div>
        )}

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} variant="tertiary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="primary"
            inactive={isConfirmDisabled}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
