import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cx from 'classnames';
import { ReactNode, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from '../sass/components/AccountSettings.module.scss';
import {
  ROUTE_ACCOUNT_ACCOUNT_PASSWORD,
  ROUTE_ACCOUNT_APPOINTMENTS,
  ROUTE_ACCOUNT_CARS,
  ROUTE_ACCOUNT_CUSTOMER_INFORMATION,
  ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  // ROUTE_FRANCHISE_MANAGER_FRANCHISES,
  ROUTE_FRANCHISE_MANAGER_PAYOUTS,
  ROUTE_FRANCHISE_INVENTORY,
} from '../util/constants';

const customerSections = [
  {
    label: 'Your Appointments',
    route: ROUTE_ACCOUNT_APPOINTMENTS,
  },
  {
    label: 'Your Cars',
    route: ROUTE_ACCOUNT_CARS,
  },
  {
    label: 'Customer Information',
    route: ROUTE_ACCOUNT_CUSTOMER_INFORMATION,
  },
  {
    label: 'Account and Password',
    route: ROUTE_ACCOUNT_ACCOUNT_PASSWORD,
  },
];

const franchiseManagerSections = [
  /* {
    label: 'Your Franchises',
    route: ROUTE_FRANCHISE_MANAGER_FRANCHISES,
  }, */
  {
    label: 'Work Orders',
    route: ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  },
  {
    label: 'Your Customers',
    route: ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
  },
  {
    label: 'Your Payout Statements',
    route: ROUTE_FRANCHISE_MANAGER_PAYOUTS,
  },
  // {
  //   label: 'Your Inventory',
  //   route: ROUTE_FRANCHISE_INVENTORY,
  // },
];

const franchiseTechnicianSections = [
  /* {
    label: 'Your Franchises',
    route: ROUTE_FRANCHISE_MANAGER_FRANCHISES,
  }, */
  {
    label: 'Work Orders',
    route: ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  },
  {
    label: 'Your Customers',
    route: ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
  },
  // {
  //   label: 'Your Inventory',
  //   route: ROUTE_FRANCHISE_INVENTORY,
  // },
];

const franchiseTesterSections = [
  {
    label: 'Work Orders',
    route: ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  },
  {
    label: 'Your Customers',
    route: ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
  },
  {
    label: 'Your Inventory',
    route: ROUTE_FRANCHISE_INVENTORY,
  },
];

export default function AccountSettings(
  props: {
    children: ReactNode,
    isManager?: boolean,
    isTechnician?: boolean,
    isTester?: boolean },
) {
  const {
    children, isManager, isTechnician, isTester,
  } = props;
  const location = useLocation();

  const getCurrentSections = () => {
    if (isManager) return franchiseManagerSections;
    if (isTechnician) return franchiseTechnicianSections;
    if (isTester) return franchiseTesterSections;
    return customerSections;
  };

  const currentSections = getCurrentSections();

  const currentSection = useMemo(
    () => currentSections.find((s) => location.pathname === s.route),
    [location, currentSections],
  );

  const [selectorOpen, setSelectorOpen] = useState(false);

  return (
    <div className={cx(styles.body, 'container--fluid')}>
      <div className={cx(styles.selectorContainer, 'container__col-xs-4')}>
        {currentSection?.label && (
          <div className={cx(styles.selectorHeader, {
            [styles.openHeader]: selectorOpen,
          })}
          >
            <button
              type="button"
              className={styles.selectorOpener}
              onClick={() => setSelectorOpen(!selectorOpen)}
            >
              <h5>{currentSection?.label}</h5>
              <KeyboardArrowDownIcon className={cx(
                styles.arrow,
                {
                  [styles.openArrow]: selectorOpen,
                },
              )}
              />
            </button>
          </div>
        )}
        <div className={cx(styles.settingSectionSelector, {
          [styles.open]: selectorOpen,
        })}
        >
          <div className={styles.items}>
            {currentSections.map((section) => (
              <div className={styles.item} key={section.label}>
                <NavLink
                  type="button"
                  to={section.route}
                  onClick={() => setSelectorOpen(false)}
                  className={({ isActive }) => cx(styles.itemButton, {
                    [styles.itemSelected]: isActive,
                  })}
                >
                  {section.label}
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={cx('container__col-xs-8', styles.settingSection)}>
        {children}
      </div>
    </div>
  );
}
