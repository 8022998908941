import { useMemo, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import TextInput from './TextInput';
import CameraAccess from './CameraAccess';
import AwsUpload from './AwsUpload';
import { IncreaseInventoryOilModalProps } from '../interfaces/Inventory';
import { convertToLiters } from '../util/oilHelper';

export default function IncreaseInventoryOilModal({
  oilId,
  oilName,
  oilWeight,
  quantity,
  setQuantity,
  setPricePerItem,
  receiptImage,
  setReceiptImage,
  onClose,
  onSubmit,
}: IncreaseInventoryOilModalProps) {
  const [imageToProcess, setImageToProcess] = useState(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [totalPrice, setTotalPrice] = useState<number | null>(null);
  const { countryCode } = useAppSelector((state) => state.franchise);

  const handleCaptureImage = async (capturedImage: any) => {
    setImageToProcess(capturedImage);
    setImageName(`receipt_oil_${oilId}_${Date.now()}.jpg`);
  };

  const onUploadComplete = (success: boolean, url: string) => {
    if (success) {
      setReceiptImage(url);
    }
    setImageToProcess(null);
    setImageName(null);
  };

  const handleQuantityChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const value = Number(target.value);
    setQuantity(value);

    if (totalPrice != null) {
      const converedQuantity = convertToLiters(String(countryCode), value);
      const pricePerItem = value > 0
        ? Math.round((totalPrice / converedQuantity) * 100) : null;
      setPricePerItem(pricePerItem);
    }
  };

  const handleTotalPriceChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const value = parseFloat(target.value);
    setTotalPrice(value);

    // Recalculate price per item if quantity is available
    if (quantity > 0) {
      const converedQuantity = convertToLiters(String(countryCode), quantity);
      const pricePerItem = value > 0
        ? Math.round((value / converedQuantity) * 100) : null;
      setPricePerItem(pricePerItem);
    }
  };

  const isConfirmDisabled = useMemo(
    () => quantity <= 0
    || totalPrice == null
    || totalPrice <= 0
    || !receiptImage,
    [quantity, totalPrice, receiptImage],
  );

  const handleConfirm = () => {
    if (totalPrice == null || quantity <= 0) {
      return;
    }

    const convertedQuantity = convertToLiters(String(countryCode), quantity);
    const pricePerItem = Math.round(
      (totalPrice / convertedQuantity) * 100,
    ) / 100;

    onSubmit({
      quantity: convertedQuantity,
      pricePerItem,
      receiptImage,
    });
  };
  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Increase Inventory Quantity</h4>

        <p className={styles.partName}>
          {`${oilName} ${oilWeight}`}
        </p>
        <p className="label">
          Total Quantity Purchased (
          {countryCode === 'US' ? 'QT' : 'L'}
          )
        </p>
        <TextInput
          type="number"
          min={1}
          placeholder="Enter total quantity"
          value={quantity.toString()}
          onChange={handleQuantityChange}
          isRequired
        />

        <p className="label">
          Total Price Paid
        </p>
        <TextInput
          type="number"
          min={0.1}
          placeholder="Enter total cost"
          value={totalPrice?.toString() || ''}
          onChange={handleTotalPriceChange}
          isRequired
        />

        {!receiptImage ? (
          <>
            <p>Add a Picture to Confirm Receipt</p>
            <CameraAccess
              onCapture={handleCaptureImage}
              className={styles.addServiceImage}
            />
            {imageToProcess && imageName && (
            <AwsUpload
              capturedImage={imageToProcess}
              filename={imageName ?? `receipt_${Date.now()}.jpg`}
              onUploadComplete={onUploadComplete}
            />
            )}
          </>
        ) : (
          <p>Picture successfully uploaded</p>
        )}

        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} variant="tertiary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="primary"
            inactive={isConfirmDisabled}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
