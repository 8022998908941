import { useState } from 'react';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import IncreaseInventoryOilModal from './IncreaseInventoryOilModal';

interface IncreaseInventoryOilContainerProps {
  oilId: string;
  oilName: string;
  oilWeight: string;
  onClose: () => void;
  onSubmit: (data: {
    quantity: number;
    pricePerItem: number | null;
    receiptImage: string }) => void;
}

export default function IncreaseInventoryOilContainer({
  oilId,
  oilName,
  oilWeight,
  onClose,
  onSubmit,
}: IncreaseInventoryOilContainerProps) {
  const [quantity, setQuantity] = useState<number>(1);
  const [pricePerItem, setPricePerItem] = useState<number | null>(null);
  const [receiptImage, setReceiptImage] = useState<string>('');

  const handleSubmit = () => {
    onSubmit({
      quantity,
      pricePerItem,
      receiptImage,
    });
    onClose();
  };

  return (
    <div className={styles.container}>
      <IncreaseInventoryOilModal
        oilName={oilName}
        oilId={oilId}
        oilWeight={oilWeight}
        quantity={quantity}
        setQuantity={setQuantity}
        setPricePerItem={setPricePerItem}
        receiptImage={receiptImage}
        setReceiptImage={setReceiptImage}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
