export const calculateOilByCountry = (
  countryCode: string,
  oilVolume: number,
): number => {
  if (countryCode === 'US') {
    const adjustedVolume = oilVolume * 1.05669;
    return parseFloat(adjustedVolume.toFixed(2));
  }
  return oilVolume;
};

export const convertToLiters = (
  countryCode: string,
  quantity: number,
): number => {
  if (countryCode === 'US') {
    const adjustedQuantity = quantity / 1.05669;
    return parseFloat(adjustedQuantity.toFixed(4));
  }
  return quantity;
};
